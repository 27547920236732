<template>
  <div class="modal fade" id="modal-form-programacion" data-backdrop="static">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-frontera-top-left pt-2 pb-2">
          <h4 class="modal-title text-white">{{ $parent.modal.title }}</h4>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            ref="closeModal"
            id="close-modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body " v-if="accion !== 1">
          <div class="card shadow">
            <div class="card-header bg-info">
              <h5 class="card-title">
                <i class="fas fa-calculator"></i> Programación
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Fecha Inicial</label>
                    <input
                      type="date"
                      v-model="form.fecha_ini"
                      class="form-control form-control-sm shadow-sm"
                      step="any"
                      :class="
                        $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @input="validarFechas"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Fecha Final</label>
                    <input
                      type="date"
                      v-model="form.fecha_fin"
                      class="form-control form-control-sm shadow-sm"
                      step="any"
                      :class="
                        $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @input="validarFechas"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="">Observación</label>
                    <textarea
                      v-model="form.observaciones"
                      type="number"
                      class="form-control form-control-sm shadow-sm"
                      step="any"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer mt-3 p-0" v-show="!$v.form.$invalid">
                <button
                  type="button"
                  class="btn btn-primary shadow mb-0"
                  @click="save()"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
          <div class="card shadow" v-if="form.id">
            <div class="card-header bg-info">
              <h5 class="card-title">
                <i class="fas fa-calculator"></i> Detalles Programación
              </h5>
            </div>
            <div class="card-body">
              <ProgramacionBonoModalDetalle
                ref="ProgramacionBonoModalDetalle"
              />
            </div>
          </div>
          <!-- <div
            class="row shadow alert-default-warning rounded border border-warning mt-2"
          >
            <div class="col-md-11">
              <div class="container testimonial-group">
                <div class="row text-center flex-nowrap">
                  sdfghjk
                </div>
              </div>
            </div>
            <div class="col-md-1 mt-2">
              <button class="btn bg-gradient-primary shadow mt-3">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div> -->
          <div class="modal-footer mt-3 p-0 ">
            <button
              type="button"
              class="btn btn-primary shadow"
              @click="save()"
              v-show="
                !$v.formModal.$invalid &&
                  $store.getters.can('hidrocarburos.bonosProgramacion.create')
              "
              v-if="accion !== 1"
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-primary shadow"
              @click="edit()"
              v-show="
                !$v.formModal.$invalid &&
                  $store.getters.can('hidrocarburos.bonosProgramacion.edit')
              "
              v-else
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="form.id && cant > 1 && $store.getters.can('hidrocarburos.bonosProgramacion.solicitudAprobacion')"
              @click="solicitarAprobacion"
            >
              <i class="fas fa-mail-bulk"></i>
              Solicitar Aprobación
            </button>
            <button
              type="button"
              class="btn btn-secondary shadow"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>

        <!-- editar-->

        <div class="modal-body" v-else>
          <div class="form-group col-md-12">
            <label for="bloque">Tipo Bono</label>
            <v-select
              :class="[
                $v.formModal.tipo_bono.$invalid ? 'is-invalid' : 'is-valid',
                $store.getters.getDarkMode ? 'dark-vselect' : '',
              ]"
              v-model="obj_tipo_bono"
              placeholder="Tipo Bono"
              label="descripcion"
              :options="$parent.listasForms.tipos_bonos"
              class="form-control form-control-sm p-0"
              @input="getSelectTipoBono()"
            ></v-select>
            <div class="error" v-if="!$v.formModal.tipo_bono.required">
              Ingrese el Tipo de Bono
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="bloque">Temporada</label>
            <v-select
              :class="[
                $v.formModal.temporada.$invalid ? 'is-invalid' : 'is-valid',
                $store.getters.getDarkMode ? 'dark-vselect' : '',
              ]"
              v-model="obj_temporada"
              placeholder="Temporada"
              label="descripcion"
              :options="$parent.listasForms.temporadas"
              class="form-control form-control-sm p-0"
              @input="selectTemporada()"
            ></v-select>
            <div class="error" v-if="!$v.formModal.temporada.required">
              Ingrese la Temporada
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="bloque">Tipo Ruta</label>
            <v-select
              :class="[
                $v.formModal.tipo_ruta.$invalid ? 'is-invalid' : 'is-valid',
                $store.getters.getDarkMode ? 'dark-vselect' : '',
              ]"
              v-model="obj_tipo_ruta"
              placeholder="Tipo Ruta"
              label="descripcion"
              :options="$parent.listasForms.tipos_ruta"
              class="form-control form-control-sm p-0"
              @input="selectTipoRuta()"
            ></v-select>
            <div class="error" v-if="!$v.formModal.tipo_ruta.required">
              Ingrese el Tipo Ruta
            </div>
          </div>

          <div class="form-group col-md-12">
            <label for="bloque">Sitio</label>
            <v-select
              :class="[
                $v.formModal.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                $store.getters.getDarkMode ? 'dark-vselect' : '',
              ]"
              v-model="sitio"
              placeholder="Sitios"
              label="nombre"
              :options="$parent.listasForms.sitios"
              class="form-control form-control-sm p-0"
              @input="getSelectSitios()"
            ></v-select>
            <div class="error" v-if="!$v.formModal.sitio_id.required">
              Ingrese el Sitio
            </div>
          </div>
          <div class="form-group col-md-12">
            <label for="bloque">Sitio Destino</label>
            <v-select
              v-model="sitio_destino"
              placeholder="Sitio Destino"
              label="nombre"
              :options="$parent.listasForms.sitios"
              class="form-control form-control-sm p-0"
              @input="selectSitioDestino()"
            ></v-select>
          </div>
          <br />
          <div class="form-group col-md-12">
            <label>Fecha inicial</label>
            <input
              type="date"
              class="form-control form-control-sm"
              style="font-size: 14px"
              v-model="formModal.fecha_ini"
              :class="
                $v.formModal.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
              "
            />
            <div class="error" v-if="!$v.formModal.fecha_ini.required">
              Ingrese la Fecha Inicio
            </div>
          </div>
          <div class="form-group col-md-12">
            <label>Fecha Final</label>
            <input
              type="date"
              class="form-control form-control-sm"
              style="font-size: 14px"
              v-model="formModal.fecha_fin"
              :class="
                $v.formModal.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
              "
            />
            <div class="error" v-if="!$v.formModal.fecha_fin.required">
              Ingrese la Fecha Fin
            </div>
          </div>
          <div class="form-group col-md-12">
            <!-- <label>Monto</label>
            <input
              type="number"
              class="form-control form-control-sm"
              style="font-size: 14px"
              v-model="formModal.monto"
              :class="$v.formModal.monto.$invalid ? 'is-invalid' : 'is-valid'"
            /> -->
            <label>Monto</label>
            <select
              class="form-control form-control-sm"
              v-model="formModal.monto"
              :class="$v.formModal.monto.$invalid ? 'is-invalid' : 'is-valid'"
            >
              <option
                v-for="monto in montos"
                :key="monto.numeracion"
                :value="monto.numeracion"
              >
                {{
                  "$ " + parseFloat(monto.descripcion).toLocaleString("es-ES")
                }}
              </option>
            </select>
            <div class="error" v-if="!$v.formModal.monto.required">
              Ingrese El Monto Total <b>(Bonificación)</b>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label>Observación</label>
            <textarea
              class="form-control form-control-sm"
              v-model="formModal.observacion"
              :class="
                $v.formModal.observacion.$invalid ? 'is-invalid' : 'is-valid'
              "
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="save()"
            v-show="
              !$v.formModal.$invalid &&
                ($store.getters.can('hidrocarburos.bonosProgramacion.create') ||
                  $store.getters.can('hidrocarburos.bonosProgramacion.edit'))
            "
            v-if="accion !== 1"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="edit()"
            v-show="
              !$v.formModal.$invalid &&
                ($store.getters.can('hidrocarburos.bonosProgramacion.create') ||
                  $store.getters.can('hidrocarburos.bonosProgramacion.edit'))
            "
            v-else
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            v-if="accion == 1"
          >
            Cerrar
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import ProgramacionBonoModalDetalle from "./ProgramacionBonoModalDetalle.vue";

export default {
  name: "ProgramacionBonoModal",
  components: {
    vSelect,
    ProgramacionBonoModalDetalle,
  },
  data() {
    return {
      accion: null,
      data_programacion: {
        programacion: {},
      },
      sitio: [],
      sitio_destino: [],
      obj_tipo_bono: [],
      obj_tipo_ruta: [],
      obj_temporada: [],
      form: {
        id: null,
        fecha_ini: null,
        fecha_fin: null,
        observaciones: null,
      },
      formModal: {
        sitio_id: null,
        sitio_destino_id: null,
        sitio: null,
        temporada: null,
        tipo_ruta: null,
        tipo_bono: null,
        monto: null,
        observacion: null,
      },
      formularios: [],
      cant: 1,
      montos: [],
    };
  },
  validations() {
    if (this.accion !== 1) {
      return {
        form: {
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
        },
        formModal: {
          sitio: {
            required,
          },
          tipo_bono: {
            required,
          },
          temporada: {
            required,
          },
          tipo_ruta: {
            required,
          },
          monto: {
            required,
          },
          observacion: {
            required,
          },
        },
      };
    } else {
      return {
        formModal: {
          sitio_id: {
            required,
          },
          fecha_ini: {
            required,
          },
          fecha_fin: {
            required,
          },
          tipo_bono: {
            required,
          },
          temporada: {
            required,
          },
          tipo_ruta: {
            required,
          },
          monto: {
            required,
          },
          observacion: {
            required,
          },
        },
      };
    }
  },
  methods: {
    async llenar_modal(item, accion) {
      this.accion = accion;
      if (accion == 1) {
        this.data_programacion = item;
        this.formModal.id = item.id;
        this.formModal.sitio_id = item.sitio_id;
        this.formModal.sitio = item.sitio;
        this.formModal.fecha_ini = item.fecha_ini;
        this.formModal.fecha_fin = item.fecha_fin;
        this.formModal.temporada = item.temporada;
        this.formModal.tipo_ruta = item.tipo_ruta;
        this.formModal.tipo_bono = item.tipo_bono;
        this.formModal.monto = item.monto;
        this.formModal.observacion = item.observacion;
        this.sitio = item.sitio;
        this.sitio_destino = item.sitio_destino;
        this.obj_tipo_bono = item.tipo_bono
          ? this.$parent.listasForms.tipos_bonos.filter(
              (data) => data.numeracion === item.tipo_bono
            )
          : [];
        this.obj_temporada = item.temporada
          ? this.$parent.listasForms.temporadas.filter(
              (data) => data.numeracion === item.temporada
            )
          : [];
        this.obj_tipo_ruta = item.tipo_ruta
          ? this.$parent.listasForms.tipos_ruta.filter(
              (data) => data.numeracion === item.tipo_ruta
            )
          : [];
      } else {
        this.formModal.id = null;
        this.formModal.sitio_id = null;
        this.formModal.sitio = null;
        this.formModal.fecha_ini = null;
        this.formModal.fecha_fin = null;
        this.formModal.temporada = null;
        this.formModal.tipo_ruta = null;
        this.formModal.tipo_bono = null;
        this.formModal.monto = null;
        this.formModal.observacion = null;
        this.sitio_destino = [];
        this.obj_tipo_bono = [];
        this.obj_tipo_ruta = [];
        this.obj_temporada = [];
        this.form.id = null;
        this.form.fecha_ini = null;
        this.form.fecha_fin = null;
        this.form.observaciones = null;
        this.formularios = [
          {
            form: {
              id: null,
              sitio: null,
              sitio_destino_id: null,
              programacion_bono_id: null,
              fecha_ini: null,
              fecha_fin: null,
              temporada: null,
              tipo_ruta: null,
              tipo_bono: null,
              monto: null,
              observacion: null,
            },
            posicion: 1,
          },
        ];
        this.cant = 1;
      }
    },

    getSelectSitios() {
      this.formModal.sitio_id = null;
      if (this.sitio) {
        this.formModal.sitio_id = this.sitio.id;
      }
    },

    selectSitioDestino() {
      this.formModal.sitio_destino_id = null;
      if (this.sitio_destino) {
        this.formModal.sitio_destino_id = this.sitio_destino.id;
      }
    },

    getSelectTipoBono() {
      this.formModal.tipo_bono = null;
      if (this.obj_tipo_bono) {
        this.formModal.tipo_bono = this.obj_tipo_bono.numeracion;
      }
    },

    selectTemporada() {
      this.formModal.temporada = null;
      if (this.obj_temporada) {
        this.formModal.temporada = this.obj_temporada.numeracion;
      }
    },

    selectTipoRuta() {
      this.formModal.tipo_ruta = null;
      if (this.obj_tipo_ruta) {
        this.formModal.tipo_ruta = this.obj_tipo_ruta.numeracion;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios
          .post("/api/hidrocarburos/programacionBono", this.form)
          .then((response) => {
            //this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.$parent.getCalendar();
            this.form.id = response.data.id;
            this.$swal({
              icon: "success",
              title: "Se actualizó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    limpiar_modal() {
      this.formModal = {
        sitio_id: null,
        sitio: null,
        fecha_ini: null,
        fecha_fin: null,
        tipo_bono_id: null,
        monto: null,
        observacion: null,
      };
    },

    edit() {
      if (!this.$v.formModal.$invalid) {
        axios
          .put("/api/hidrocarburos/detProgramacionBono", this.formModal)
          .then((response) => {
            this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "Se actualizó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async addForm(form) {
      let index = this.cant - 1;
      this.formularios[index].form = form;
      this.cant++;
      let form_det = {
        form: {
          id: null,
          sitio: null,
          programacion_bono_id: null,
          fecha_ini: null,
          fecha_fin: null,
          temporada: null,
          tipo_ruta: null,
          tipo_bono: null,
          monto: null,
          observacion: null,
        },
        posicion: this.cant,
      };
      this.formularios.push(form_det);
    },

    getMontosBonos() {
      axios.get("/api/lista/203").then((response) => {
        this.montos = response.data;
      });
    },

    validarFechas() {
      if (this.form.fecha_ini) {
        const hoy = new Date();
        const fecha_ini = new Date(this.form.fecha_ini);
        fecha_ini.setDate(fecha_ini.getDate() + 1);

        if (hoy >= fecha_ini) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser mayor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.fecha_fin) {
        var fecha_menor = new Date(this.form.fecha_ini);
        var fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.fecha_fin) {
        fecha_menor = new Date(this.form.fecha_ini).getTime();
        fecha_mayor = new Date(this.form.fecha_fin).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      /* if (this.form.fecha_fin) {
        var hoy = new Date();
        const fecha_fin = new Date(this.form.fecha_fin);
        fecha_fin.setDate(fecha_fin.getDate());
        if (fecha_fin >= hoy) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } */
    },

    solicitarAprobacion() {
      this.$swal({
        title: "Está seguro de realizar la solicitud?",
        text:
          "Los cambios no se pueden revertir una vez se envíe la solicitud!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Solicitar!",
      }).then(async (result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .post("/api/hidrocarburos/programacionBono/solicitudAprobacion", {
              id: this.form.id,
            })
            .then((response) => {
              this.$refs.closeModal.click();
              this.$swal({
                icon: "success",
                title: `Solicitud enviada con éxito...`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.cargando = false;
            });
        }
      });
    },
  },
  mounted() {
    this.getMontosBonos();
  },
};
</script>
<style>
/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  scrollbar-color: rgb(255, 255, 255) #45c4ff9a;
  white-space: nowrap;
}
.testimonial-group > .row > .col-sm-4 {
  display: inline-block;
  float: none;
}
</style>
